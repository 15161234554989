import React, { useState, useEffect } from "react";
import {ReactComponent as ANBIMA} from './assets/ANBIMA.svg'
import './App.scss'
// import PowerBI from "./components/PowerBI";

function App({kc}) {
  // const [loading, setLoading] = useState(true);
  const [profile, setProfile] = useState({});
  // const [config, setConfig] = useState({});

  useEffect(() => {
    // const myHeaders = new Headers();
    //   myHeaders.append("Content-Type", "application/json");
    //   myHeaders.append("Authorization", `Bearer ${kc.token}`);

    // const requestOptions = {
    //   method: "POST",
    //   headers: myHeaders,
    //   body: JSON.stringify({
    //     authenticationType: "SERVICE_PRINCIPAL",
    //     groupId: "58c76f9b-d65f-4854-b412-123ce129b53c",
    //     reportId: "814979bf-e494-437e-b2a0-3488e996007d",
    //   }),
    //   redirect: "follow",
    // };

    // fetch('https://analytics-powerbi-embed-cer.apps.anbima.com.int/analytics/embedinfo', requestOptions)
    //   .then((response) => response.json())
    //   .then((result) => {
    //     setConfig({
    //       embedUrl: result.embedReports[0]?.embedUrl,
    //       accessToken: result.embedToken,
    //     })
    //     setLoading(false)
    //   })
    //   .catch((error) => console.log('error', error));

    kc.loadUserProfile()
      .success(resp => setProfile(resp))
      .error(err => console.log('erro ao carregar kc profile', err))
  }, []);

  
  return (
    <div className="App">
      <header>
        <figure>
          <ANBIMA />
        </figure>
        <h1>Book de debêntures</h1>
        <p>
          {profile && (
            <>
            <span className="user-name">{profile.firstName}</span>
            {` | `}
            </>
            )
          }
          <a className="logout-btn" onClick={() => kc.logout()}>sair</a>
        </p>
      </header>
      {/* {loading ? <span className='loading-text'>Carregando...</span> : <PowerBI config={config} />} */}
      
      {/* provisorio para o link aberto */}
      <div className="iframe-powerbi">
        <iframe
          title="Book de debêntures"
          src="https://app.powerbi.com/view?r=eyJrIjoiNTIyYmEyYjctMTBjMi00NjQ1LWE5OWQtZTdlMzAyNTMwMGEzIiwidCI6Ijk3OTM3M2VkLWQxMzAtNDU4NS1iNTY5LTNjM2NlNjE0MTIyNyJ9"
          width="1280px"
          height="720"
          frameborder="0"
          allowfullscreen=""
          display="block"
        ></iframe>
      </div>
      {/* <textarea style={{Margin: '200px'}}>{kc.token}</textarea> */}
    </div>
  );
}

export default App;
