import React from 'react';
import ReactDOM from 'react-dom';
import Keycloak from 'keycloak-js';
import App from './App';

const kc = new Keycloak()
const hasPermission = roles => !!roles && roles.includes("BOOK_DEBENTURES")

kc.init({ onLoad: 'login-required', checkLoginIframe: false })
  .success((authenticated) => {
    if (authenticated && hasPermission(kc.realmAccess.roles)) {
      ReactDOM.render(
        <React.StrictMode>
          <App kc={kc} />
        </React.StrictMode>,
        document.getElementById('root')
      );
    } else {
      kc.logout()
    }
    
  })
  .error((err) => {
    console.log('erro ao autenticar usuario no kc', err)
    kc.logout()
  })
